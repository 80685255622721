import { TimelineMax, Power1 } from "gsap";

$(document).ready(function () {

    $(".watch-full-explainer-home").click(function(){

        window.trackEvent("Video", "Clicked watched full trailer on home page");

    })

    $("a.smooth").on('click', function (event) {

        // Make sure this.hash has a value before overriding default behavior
        if (this.hash !== "") {
            // Prevent default anchor click behavior
            event.preventDefault();

            // Store hash
            var hash = this.hash;

            // Using jQuery's animate() method to add smooth page scroll
            // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
            $('html, body').animate({
                scrollTop: $(hash).offset().top - 50
            }, 800, function () {

                // Add hash (#) to URL when done scrolling (default click behavior)
                // window.location.hash = hash;
            });
        } // End if
    });

    // Home logo

    $("#home-logo").css("opacity", 1);

    const length = 200;

    const path_first_ten = $("#home-logo path:nth-child(-n+10)");
    const path_adventure = $("#home-logo path:nth-child(n+11):nth-child(-n+19)");
    const path_games = $("#home-logo path:nth-child(n+20)");

    const logo = $("#home-logo");

    path_first_ten.css({
        "stroke-dashoffset": length,
        "stroke-dasharray": length,
        "fill-opacity": "0",
        "transform": "translateY(-10px)"
    });

    const drawLogoTL = new TimelineMax({
    });
    drawLogoTL
        .staggerTo(path_first_ten, 0.8, { strokeDashoffset: 0, y: 0, x: 0, ease: Power1.easeInOut }, 0.1)
        .to(path_first_ten, 0.5, { fillOpacity: 1, ease: Power1.easeOut })
        .from(path_adventure, 0.5, { y: -20, opacity: 0, ease: Power1.easeOut })
        .from(path_games, 0.5, { y: -20, opacity: 0, ease: Power1.easeOut });

});

